html, body, everyincome {
  display: block;
  height:  100%;
  width:   100%;
}

html {
  background-color: var(--site-bg);
  font-size:        16px;
  font-family:      var(--font-body);
}

@media screen and (max-width: 600px) {
  html {
    font-size: 14px;
  }
}

*, *:before, *:after {
  box-sizing:             border-box;
  -webkit-font-smoothing: antialiased;
  font-smoothing:         antialiased;
}

.ei-loading {
  position:   relative;
  overflow:   hidden;
  min-height: 48px;
  min-width:  48px;

  &:before, &:after {
    content:   " ";
    position:  absolute;
    z-index:   150;
    animation: fade 100ms forwards reverse;
  }

  &:before {
    top:              0;
    left:             0;
    right:            0;
    bottom:           0;
    background-color: rgba(255,255,255,0.8);
  }

  &:after {
    left:                50%;
    top:                 50%;
    transform:           translate(-50%, -50%);
    width:               90%;
    height:              90%;
    max-width:           80px;
    max-height:          80px;

    background-image:    url(https://cdn.everyincome.com/assets/ei-spinner.svg);
    background-size:     contain;
    background-repeat:   no-repeat;
    background-position: center;
  }
}
